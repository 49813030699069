import React from 'react'
// Style
import {
  Container
} from './Button.styles';

const Button = ({
  children,
  to
}) => {

  return(
    <Container
      to={to}
    >
      {children}
    </Container>
  )
}

export default Button