import styled from 'styled-components';
import { CopyRegular } from '../copy.styles'
import { BorderedImage,ShadowedBox } from '../ui.styles'
import { Container1200 } from '../../components/Container1200';
import { colors as c } from '../colors';

const Section = styled.section`
  margin-bottom: 70px;
`;

export const Banner = styled(Section)`
  ${ShadowedBox};
  width: 100%;

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 280px;
    height: auto;
    margin: 0 0 20px 0;

    @media (min-width: 768px) {
      max-width: 100%;
      flex-basis: 35%;
      margin: 0 0 0 60px;
    }
  }

  img {
    ${BorderedImage};
  }
`;

export const StyledContainer1200 = styled(Container1200)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 40px 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    padding: 50px;
  }
`;

export const Greeting = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 350px;

  @media (min-width: 768px) {
    max-width: 100%;
  }

  h1 {
    font-size: 5rem;
    text-align: center;

    @media (min-width: 768px) {
      text-align: left;
      font-size: 6rem;
    }

    @media (min-width: 1300px) {
      text-align: left;
      font-size: 7rem;
    }

    span {
      display: block;
    }
  }
`;

export const Name = styled.span`
  position: relative;
  color: ${c.bluePrimary};
  font-weight: bold;
  margin-bottom: 2px;

  @media (min-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const City = styled.span`
  color: ${c.grayText};
  font-size: 1.8rem;
  margin-bottom: 26px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1300px) {
    font-size: 2.2rem;
    margin-bottom: 60px;
  }
`;

export const GreetingSecondLine = styled.span`
  display: inline-block;
  font-size: 2.5rem;
  line-height: 1.3;
  letter-spacing: .25px;

  @media (min-width: 768px) {
    font-size: 2.75rem;
  }

  @media (min-width: 1300px) {
    font-size: 3rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  @media (min-width: 768px) {
    justify-content: flex-start;
    margin-top: 3rem;
  }

  @media (min-width: 1300px) {
    margin-top: 4rem;
  }
`;

export const About = styled(Section)`

`;

export const Projects = styled(Section)`

`;

export const BlurbBox = styled.div`
  ${ShadowedBox};
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

export const Blurbs = styled.div`
  flex: 1;
`;

export const Blurb = styled.p`
  ${CopyRegular};

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

export const Showcase = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
