import styled from 'styled-components';
import { Link } from "gatsby";
import { colors as c } from '../../styles/colors';

export const Container = styled(Link)`
  display: inline-block;
  background-color: ${c.bluePrimary};
  color: ${c.grayBGPrimary};
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 10px 16px;
  border-radius: 6px;
  border: 1px solid ${c.bluePrimary};
  outline: none;
  cursor: pointer;
  transition: all .1s linear;

  &:hover {
    background-color: transparent;
    color: ${c.bluePrimary};
  }
`;
