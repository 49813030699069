import React from "react";
// Components
import { StaticQuery,graphql } from 'gatsby';
import Layout from "../components/layout";
import { Seo } from "../components/Seo";
import { Button } from '../components/Button';
import { Container1200 } from '../components/Container1200';
import { HomeImage } from '../components/HomeImage';
import { ShowcaseItem } from '../components/ShowcaseItem';
import { TitleWithLine } from '../components/TitleWithLine';
// Style
import {
  About,
  Banner,
  Blurb,
  Blurbs,
  BlurbBox,
  ButtonWrapper,
  City,
  Greeting,
  GreetingSecondLine,
  Name,
  Projects,
  Showcase,
  StyledContainer1200
} from '../styles/pages-styles/index.styles';

const Index = () => {
  const query = graphql`
    query MyWorkItemsQuery {
      allMyWorkJson {
        edges {
          node {
            alt
            id
            image {
              childImageSharp {
                fluid(
                  maxWidth: 384
                  quality: 100
                  traceSVG: { color: "#BDBDBD" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            title
            url
          }
        }
      }
    }
  `;
 
  return (
    <Layout>
      <Seo title="DaveJudd.com | Portfolio, Projects, and Blog" />
      <Banner id="banner">
        <StyledContainer1200>
          <Greeting>
            <h1>
              <Name>Dave Judd</Name>
              <City>Minneapolis, MN, USA</City>
              <GreetingSecondLine>Hi, I'm Dave. Let's make something cool!</GreetingSecondLine>
            </h1>
            <ButtonWrapper>
              <Button to="/contact">Get In Touch</Button>
            </ButtonWrapper>
          </Greeting>
          <HomeImage />
        </StyledContainer1200>
      </Banner>
      <About id="about">
        <Container1200>
          <TitleWithLine>About Me</TitleWithLine>
          <BlurbBox>
            <Blurbs>
              <Blurb>Hi, I’m Dave Judd. I work as a Front End Web Developer at Starkey Hearing Technologies where I build and maintain web sites and applications that serve 25+ locales in 10+ languages globally to aid in the mission of giving hearing to the world.</Blurb>
              <Blurb>I also work as a freelance developer and designer. Check out <a href="https://www.upwork.com/freelancers/~01b98b74983305088e?s=1110580755057594368" target="_blank" rel="noreferrer">my Upwork profile</a> to see some of my latest freelance work!</Blurb>
            </Blurbs>
            <HomeImage />
          </BlurbBox>
        </Container1200>
      </About>
      <Projects id="projects">
        <Container1200>
          <TitleWithLine>My Work</TitleWithLine>
          <Showcase>
            <StaticQuery
              query={query}
              render={data => (
                  <>
                    {data.allMyWorkJson.edges.map(({ node }) => (
                        <ShowcaseItem
                          key={node.id}
                          image={node.image.childImageSharp.fluid}
                          to={node.url}
                        >
                          <h2>{node.title}{node.linkType === 'external' && <i className="fas fa-external-link-alt"></i>}</h2>
                        </ShowcaseItem>
                      )
                    )}
                  </>
              )}
            >
            </StaticQuery>
          </Showcase>
        </Container1200>
      </Projects>
    </Layout>
  )
}

export default Index;
